<template>
  <div class="home-container" @click="handleContainerClick">
    <ROEShortExplanation />
    <ROECalculator 
      :selectedCalculation="selectedCalculation"
      @calculation-made="addCalculation"
      @calculation-updated="updateCalculation"
      @edit-cancelled="clearSelectedCalculation"
    />
    <ROETable 
      :calculations="calculations"
      :selectedRowIndex="selectedRowIndex"
      @row-selected="handleRowSelected"
      @selection-cleared="clearSelectedCalculation"
      @row-deleted="deleteCalculation"
    />
    <ROEExplanation />
  </div>
</template>

<script>
import ROEShortExplanation from '../components/ROEShortExplanation.vue'
import ROECalculator from '../components/ROECalculator.vue'
import ROETable from '../components/ROETable.vue'
import ROEExplanation from '../components/ROEExplanation.vue'

export default {
  name: 'App',
  components: {
    ROEShortExplanation,
    ROECalculator,
    ROETable,
    ROEExplanation,
  },
  data() {
    return {
      calculations: [],
      selectedCalculation: null,
      selectedRowIndex: -1
    };
  },
  methods: {
    addCalculation(calculation) {
      this.calculations.push(calculation);
    },
    updateCalculation(updatedCalculation) {
      const index = this.calculations.findIndex(calc => calc === this.selectedCalculation);
      if (index !== -1) {
        this.calculations[index] = updatedCalculation;
        this.clearSelectedCalculation();
      }
    },
    deleteCalculation(index) {
      this.calculations.splice(index, 1);
      this.clearSelectedCalculation();
    },
    handleRowSelected(calculation) {
      this.selectedCalculation = calculation;
      this.selectedRowIndex = this.calculations.indexOf(calculation);
    },
    clearSelectedCalculation() {
      this.selectedCalculation = null;
      this.selectedRowIndex = -1;
    },
    handleContainerClick(event) {
      // Only clear if clicking directly on the container, not its children
      if (event.target === event.currentTarget) {
        this.clearSelectedCalculation();
      }
    }
  }
}
</script>

<style scoped>
.home-container {
  min-height: 100vh;
  padding: 20px;
}
</style>