<!-- src/components/ROEShortExplanation.vue -->
<template>
  <div class="roe-short-explanation">
    <h2>Vergleichen Sie, wie viel Sie verdienen können</h2>
    <p>
      Ein Immobilien-ROE-Rechner ermöglicht es Investoren, die Rentabilität ihres investierten Eigenkapitals zu analysieren. 
      Dabei fließen verschiedene Aspekte in die Berechnung ein, wie der Kaufpreis der Immobilie, das eingesetzte Eigenkapital, die Zinsen für Fremdkapital sowie laufende Kosten. 
      Mit Hilfe des Rechners wird ersichtlich, wie effizient das Eigenkapital bei einer Immobilieninvestition eingesetzt wird.
    </p>
  </div>
</template>

<style scoped>
.roe-short-explanation {
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 4px;
  line-height: 1.6;
}

h1, h2 {
  color: #2c3e50;
  margin-bottom: 0.5em;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

p {
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  .roe-short-explanation {
    padding: 0.5rem;
  }
}
</style>
