<template>
  <header class="header">
    <div class="header-content">
        <h1 class="site-name">
          <router-link to="/">
          <p>Immobilien</p>
          <p>Rentabilitätsrechner</p>    
      </router-link>
        </h1>
      <p class="site-description">Berechne und vergleiche Immobilien basierend auf dem ROE (Return on Equity).</p>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>

<style scoped>
.header {
  background-color: #174EA6;
  color: #FFFFFF;
  padding: 1rem;
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.site-name {
  font-size: 1.7rem;
  margin: 0;
}

.site-description {
  font-size: 1rem;
  margin: 0;
  text-align: right;
  max-width: 50%;
}

a{
    text-decoration: none;
    color: inherit;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .site-description {
    text-align: left;
    max-width: 100%;
    margin-top: 0.5rem;
  }
}
</style>
